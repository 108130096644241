<template>
  <div class="wrap">
    <div class="clean"></div>
    <!-- <van-nav-bar title="检查预约" left-arrow @click-left="onClickLeft" /> -->
    <div class="info">
      <div class="info_box">
        <div class="box_1">就诊人</div>
        <div class="box_2">{{ info.name }}</div>
      </div>
      <div class="info_box">
        <div class="box_1">就诊卡号</div>
        <div class="box_2">{{ cardNo }}</div>
      </div>
      <!-- <div class="info_box">
                <div class="box_1">手机号</div>
                <div class="box_2">{{pPhone}}</div>
            </div>
            <div class="info_box" style="border-bottom: 1px solid #DCDCDC;padding-bottom: 10px;">
                <div class="box_1">身份证号</div>
                <div class="box_2">{{pCardNo}}</div>
            </div>
            <div class="info_box">
                <div class="box_1">就诊医院</div>
                <div class="box_2">{{hospitalName}}</div>
            </div> -->
      <div class="info_box">
        <div class="box_1">检验申请单号</div>
        <div class="box_2">{{ info.testNo }}</div>
      </div>
      <div class="info_box">
        <div class="box_1">检验项</div>
        <div class="box_2">{{ info.subject }}</div>
      </div>
      <div class="info_box">
        <div class="box_1">标本</div>
        <div class="box_2">{{ info.specimen }}</div>
      </div>
      <div class="info_box">
        <div class="box_1">申请时间</div>
        <div class="box_2">{{ info.requestDateTime }}</div>
      </div>
      <div class="info_box">
        <div class="box_1">申请科室</div>
        <div class="box_2">{{ info.requestDeptName }}</div>
      </div>
      <div class="info_box">
        <div class="box_1">申请医生</div>
        <div class="box_2">
          {{ info.requestPhysician == null ? "--" : info.requestPhysician }}
        </div>
      </div>
      <div class="info_box">
        <div class="box_1">执行科室</div>
        <div class="box_2">{{ info.performDeptName }}</div>
      </div>
      <div class="info_box">
        <div class="box_1">报告时间</div>
        <div class="box_2">{{ info.reportDateTime }}</div>
      </div>
      <div class="info_box">
        <div class="box_1">报告人</div>
        <div class="box_2">{{ info.reporter }}</div>
      </div>
      <div
        class="info_box"
        style="border-bottom: 1px solid #dcdcdc; padding-bottom: 10px"
      >
        <div class="box_1">验证人</div>
        <div class="box_2">{{ info.verifiedBy }}</div>
      </div>
      <div>
        <div class="title">
          <span class="subTitle"> 检验结果</span>
          <span class="title_right">{{ info.subject }}</span>
        </div>
        <div class="card" v-for="(item, i) in infoList" :key="i">
          <div class="info_box">
            <div class="box_1">
              {{ item.reportItemName }}
              <span
                class="tipsHeight"
                :style="
                  item.abnormalFlag == 'H' ? 'color:#FF1C1C' : 'color:#1AB29B'
                "
              >
                {{
                  item.abnormalFlag == "H"
                    ? "↑"
                    : item.abnormalFlag == "L"
                    ? "↓"
                    : ""
                }}
              </span>
            </div>
            <div class="box_2">
              {{ item.result }}<span v-if="item.units">({{ item.units }})</span>
            </div>
          </div>
          <div style="font-size: 14px; color: #999999">
            正常范围：{{ item.printContext }}
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="btn dynamic_btn" @click="goDetail">查看影像</div> -->
    <!-- <div class="btn_1" @click="goDetail">线上预览</div> -->
  </div>
</template>
<script>
import { getList, getInspectionDetail } from "@/api/inspectionQuery";

export default {
  data() {
    return {
      user: "",
      cardNo: this.$route.query.cardNo,
      pCardNo: "",
      pPhone: "",
      hospitalName: "",
      info: JSON.parse(localStorage.getItem("itemData")),
      infoList: [],
      checkData: [],
    };
  },
  created() {
    // this.getdata();
    window.scrollTo(0, 0);
    this.InspectionDetail();
  },
  methods: {
    // getdata() {
    //   getList({
    //     patientId: this.$route.query.cardNo,
    //   }).then((res) => {
    //     if (res.data.code == "200") {
    //       this.dataList = res.data.data;
    //     }
    //   });
    // },
    // 检验详情
    InspectionDetail() {
      getInspectionDetail({ testNo: this.info.testNo }).then((res) => {
        if (res.data.code == "200") {
          this.infoList = res.data.data;
        }
      });
    },
    onClickLeft() {
      this.$router.push({ path: "/electronicQuery" });
    },
    goDetail(item) {
      this.$router.push({
        path: "/electronicView",
        query: {
          cardNo: this.$route.query.cardNo,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.wrap {
  min-height: 100vh;
  background: #f9fdfe;
  padding-bottom: 16px;
  .clean {
    height: 16px;
  }
  .info {
    margin: 0 16px;
    background: #ffffff;
    box-shadow: 0px 5px 5px 1px rgba(239, 247, 246, 1);
    border-radius: 10px 10px 10px 10px;
    padding: 10px;

    .info_box {
      display: flex;
      justify-content: space-between;
      // align-items: center;
      margin-bottom: 10px;

      .box_1 {
        font-size: 15px;
        color: #333333;
      }

      .box_2 {
        color: #666666;
        font-size: 14px;
      }
    }

    .title {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      font-weight: bold;
      color: #1578f9;
      font-size: 16px;
      margin-bottom: 20px;

      .title_right {
        width: 65%;
        text-align: right;
        // float: right;
        font-weight: bold;
        color: #333333;
        font-size: 14px;
      }
    }

    .content {
      margin-top: 12px;
      color: #666666;
      font-size: 14px;
    }

    .info_box2 {
      // display: flex;
      margin-top: 12px;

      .box_1 {
        font-size: 15px;
        color: #333333;
        line-height: 30px;
      }

      .box_2 {
        color: #666666;
        font-size: 14px;
      }
    }
  }

  .btn {
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    color: #fff;
    background: #1ab29b;
    border-radius: 23px 23px 23px 23px;
    margin: 16px 26px;
  }

  .btn_1 {
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    color: #1ab29b;
    border-radius: 23px 23px 23px 23px;
    margin: 0 26px;
    border: 1px solid #1ab29b;
  }
}

/deep/.van-hairline--top-bottom:after,
.van-hairline-unset--top-bottom:after {
  border-width: 0px 0;
}

/deep/.van-stepper {
  float: right;
}

/deep/.van-nav-bar__content {
  background-color: #1ab29b;
  color: #fff;
}

/deep/.van-nav-bar__title {
  color: #fff;
  font-size: 18px;
  max-width: 100%;
}

/deep/.van-icon-arrow-left {
  color: #fff;
}
.card {
  border-bottom: 1px solid #dcdcdc;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.card:last-child {
  margin-bottom: 0;
  border-bottom: none;
  padding-bottom: 0px;
}
.tipsHeight {
  //position: absolute;
  margin-left: 5px;
}
</style>
